import React, { useEffect } from "react";
import { Link as Link2 } from "react-router-dom";

import Typed from "typed.js";

import BackgroudImage from "../assets/images/bg/1.jpeg";
import Services from "../component/Services";
import About from "../component/About";
import Pricing from "../component/Pricing";
import Contact from "../component/Contact";
import Footer from "../component/Footer";
import Portfolio from "../component/Portfolio";
import Switcher from "../component/Switcher";
import Navbar from "../component/navbar";
import { useTranslation } from "react-i18next";

/**
 * Index Component
 */
export default function Index() {
  const {t} = useTranslation();

  useEffect(() => {
    const timer = setTimeout(() => {
      new Typed("#typed", {
        strings: [
          `<b>${t("Development")}</b>`,
          `<b>${t("Construction")}</b>`,
          `<b>${t("Interior and exterior finishing works")}</b>`,
          `<b>${t("Landscape works in line with the design")}</b>`,
        ],
        backDelay: 2000,
        loop: true,
        startDelay: 300,
        typeSpeed: 100,
        backSpeed: 100,
      });
    }, 500);

    return () => {
      clearTimeout(timer);
    }
  }, [])



  return (
    <>
      <div>
        <Navbar/>
        <section
          style={{ backgroundImage: `url(${BackgroudImage})` }}
          className="py-36 lg:py-64 w-full table relative bg-center bg-cover"
          id="home"
        >
          <div className="absolute inset-0 bg-black opacity-80"></div>
          <div className="container relative">
            <div className="grid grid-cols-1 mt-12">
              <h4 className="text-white lg:text-5xl text-4xl lg:leading-normal leading-normal font-medium mb-7 position-relative">
                {t('Providing Brilliant Ideas')} <br />
                {t('For')} {" "}
                <span
                  className="typewrite relative text-type-element"
                  id="typed"
                  data-period="2000"

                ></span>
              </h4>

              <p className="text-white opacity-50 mb-0 max-w-2xl text-lg">
              {t("AL ATABA ENGINEERING GENERAL CONTRACTING - SOLE PROPRIETORSHIP L.L.C")}
              </p>
              <p className="text-white opacity-40 mb-0 max-w-2xl text-sm">
              {t("whiteHandTitle")}
              </p>

              <div className="relative mt-10">
                <Link2
                  to="#"
                  className="btn bg-orange-600 hover:bg-orange-700 border-orange-600 hover:border-orange-700 text-white rounded-md"
                >
                  {t("Get Started")}
                </Link2>
              </div>
            </div>
          </div>
        </section>

        {/* About section */}
        <About />

        {/* Service section */}
        <Services />

        {/* Portfolio section */}
        <Portfolio />

        {/* Pricing section */}
        <Pricing />

        {/* Contact section */}
        <Contact />

        {/* Footer section */}
        <Footer />

        {/* Switcher section */}
        <Switcher />
      </div>

    </>
  );

}

