import React from "react";

import Team from "./Team";

export default function Pricing() {

    return (
        <>
            {/* Start */}
            <Team />
        </>
    )


}
