import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-scroll";

export default function Navbar({navdark}){
  const [isOpen, setMenu] = useState(true);
  const { t } = useTranslation();
  
  useEffect(()=>{
    window.addEventListener("scroll", windowScroll);
    window.scrollTo(0, 0);
    return()=>{
      window.removeEventListener( 'scroll', windowScroll )
    }

  },[])
  
  const toggleMenu = () => {
    setMenu(!isOpen)
  }

    function windowScroll() {
        const navbar = document.getElementById("navbar");
        if (
          document.body.scrollTop >= 50 ||
          document.documentElement.scrollTop >= 50
        ) {
          navbar.classList.add("is-sticky");
        } else {
          navbar.classList.remove("is-sticky");
        }
      }
    return(
        <>
        <nav className="navbar" id="navbar">
          <div className="container flex flex-wrap items-center justify-end">
            {/* {navdark && (

                <Link2 className="navbar-brand" to="/">
                    <span>
                        <img src={LogoDark} className="inline-block dark:hidden" alt="" />
                        <img src={LogoLight} className="hidden dark:inline-block" alt="" />
                    </span>
                </Link2>
            )}
            {!navdark && (

                <Link2 className="navbar-brand" to="/">
                <span className="inline-block dark:hidden">
                    <img src={LogoDark} className="l-dark" alt="" />
                    <img src={LogoLight} className="l-light" alt="" />
                </span>
                <img
                    src={LogoLight}
                    className="hidden dark:inline-block"
                    alt=""
                />
                </Link2>
            )} */}

            <div className="nav-icons flex items-center lg_992:order-2 ms-auto">
              <button
                type="button"
                className="collapse-btn inline-flex items-center ms-3 text-dark dark:text-white lg_992:hidden"
                onClick={toggleMenu}
              >
                <span className="sr-only">Navigation Menu</span>
                <i className="mdi mdi-menu mdi-24px"></i>
              </button>
            </div>

            <div
              className={`${isOpen === true ? 'hidden' : 'block'} navigation lg_992:order-1 lg_992:flex`}
              id="menu-collapse"
            >
              <ul className={`navbar-nav ${navdark ? '' : 'nav-light'}`} id="navbar-navlist">
                <Link
                  className="nav-item"
                  to="home"
                  activeclassname="active"
                  spy={true}
                  smooth={true}
                  duration={500}
                >
                  <span className="nav-link">{t("Home")}</span>
                </Link>
                <Link
                  className="nav-item"
                  activeclassname="active"
                  spy={true}
                  smooth={true}
                  duration={500}
                  to="about"
                >
                  <span className="nav-link">{t("About us")}</span>
                </Link>
                <Link
                  className="nav-item"
                  to="features"
                  activeclassname="active"
                  spy={true}
                  smooth={true}
                  duration={500}
                >
                  <span className="nav-link">{t("Services")}</span>
                </Link>
                <Link
                  className="nav-item"
                  to="portfolio"
                  activeclassname="active"
                  spy={true}
                  smooth={true}
                  duration={500}
                >
                  <span className="nav-link">{t("Portofolio")}</span>
                </Link>
                <Link
                  className="nav-item"
                  to="pricing"
                  activeclassname="active"
                  spy={true}
                  smooth={true}
                  duration={500}
                >
                  <span className="nav-link">{t("Strategic Partners")}</span>
                </Link>
                <Link
                  className="nav-item"
                  to="contact"
                  activeclassname="active"
                  spy={true}
                  smooth={true}
                  duration={500}
                >
                  <span className="nav-link">{t("Contact us")}</span>
                </Link>
              </ul>
            </div>
          </div>
        </nav>
        </>
    )
}   