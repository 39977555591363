import React from "react";

import avatar1 from "../assets/images/client/01.jpg";
import avatar2 from "../assets/images/client/02.jpg";
import avatar3 from "../assets/images/client/3.jpg";
import avatar4 from "../assets/images/client/4.jpg";

import { useTranslation } from "react-i18next";

export default function Team() {
    const {t} = useTranslation()
    const team = [
        {
            profile: avatar1,
        },
        {
            profile: avatar2,
        },
        {
            profile: avatar3,
        },
        {
            profile: avatar4,
        },
    ]

    return (
        <>
            <section className="relative md:py-24 py-16 bg-gray-50 dark:bg-slate-800" id="team">
                <div className="container">
                    <div className="grid grid-cols-1 pb-8 text-center">
                        <h6 className="text-orange-600 text-base font-medium uppercase mb-2">{t("Strategic Partners")}</h6>
                        <p className="text-slate-400 dark:text-slate-300 max-w-xl mx-auto">{t("Within the framework of the strategic partnership and in completion of the work methodology, the Engineering Threshold for General Contracting was registered in the following companies and entities")}</p>
                    </div>

                    <div className="grid grid-cols-1 lg:grid-cols-4 md:grid-cols-2 gap-6 mt-8">
                        {team.map((member, index) => (

                            <div key={index} className="relative rounded-md shadow-lg overflow-hidden group">
                                <img src={member.profile} className="group-hover:origin-center group-hover:scale-105 transition duration-500" alt="" />
                                <div className="absolute inset-0 bg-black opacity-0 group-hover:opacity-30 z-0 transition duration-500"></div>
                            </div>
                        ))}
                    </div>
                </div>
                {/* <!--end container--> */}
            </section>
        </>
    )

}


