import React, { useState } from "react";
import { useTranslation } from "react-i18next";

export default function Contact() {
    const {t} = useTranslation()
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        subject: "",
        comments: "",
      });
    
      const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
      };
    
      const handleSubmit = (e) => {
        e.preventDefault();
        const mailtoLink = `mailto:alatab.engineering@gmail.com?subject=${encodeURIComponent(
          formData.subject
        )}&body=${encodeURIComponent(
          `Name: ${formData.name}\n\n ${formData.comments}`
        )}`;
        window.location.href = mailtoLink;
        setFormData({
            name: "",
            email: "",
            subject: "",
            comments: "",
          });
        e.target.reset();
      };
    return (
        <>
            {/* Start */}
            <section className="relative md:py-24 py-16 bg-gray-50 dark:bg-slate-800" id="contact">
                <div className="container">
                    <div className="grid grid-cols-1 pb-8 text-center">
                        <h6 className="text-orange-600 text-base font-medium uppercase mb-2">{t("Contact us")}</h6>

                        <p className="text-slate-400 dark:text-slate-300 max-w-xl mx-auto">{t("Contact us to start your next project, and let our expertise ensure its success from concept to completion.")}</p>
                    </div>

                    <div className="grid grid-cols-1 lg:grid-cols-12 md:grid-cols-2 mt-8 items-center gap-6">
                        <div className="lg:col-span-8">
                            <div className="p-6 rounded-md shadow bg-white dark:bg-slate-900">
                                <form onSubmit={handleSubmit}>
                                    <div className="grid lg:grid-cols-12 lg:gap-6">
                                        <div className="lg:col-span-6 mb-5">
                                            <input name="name" id="name" type="text" className="form-input" placeholder={t("Name :")} onChange={handleChange}/>
                                        </div>

                                        <div className="lg:col-span-6 mb-5">
                                            <input name="email" id="email" type="email" className="form-input" placeholder={t("Email :")} onChange={handleChange}/>
                                        </div>
                                    </div>

                                    <div className="grid grid-cols-1">
                                        <div className="mb-5">
                                            <input name="subject" id="subject" className="form-input" placeholder={t("Subject :")} onChange={handleChange}/>
                                        </div>

                                        <div className="mb-5">
                                            <textarea name="comments" id="comments" className="form-input textarea h-28" placeholder={t("Message :")} onChange={handleChange}></textarea>
                                        </div>
                                    </div>
                                    <button type="submit" id="submit" name="send" className="btn bg-orange-600 hover:bg-orange-700 border-orange-600 hover:border-orange-700 text-white rounded-md h-11 justify-center flex items-center">{t("Send Message")}</button>
                                </form>
                            </div>
                        </div>

                        <div className="lg:col-span-4">
                            <div className="lg:ms-8">
                                <div className="flex">
                                    <div className="icons text-center mx-auto">
                                        <i className="uil uil-phone block rounded text-2xl dark:text-white mb-0"></i>
                                    </div>

                                    <div className="flex-1 ms-6">
                                        <h5 className="text-lg dark:text-white mb-2 font-medium">{t("Phone")}</h5>
                                        <a className="text-slate-400">{t("+971508209286  |  +971559882707  |  +97137363897")}</a>
                                    </div>
                                </div>

                                <div className="flex mt-4">
                                    <div className="icons text-center mx-auto">
                                        <i className="uil uil-envelope block rounded text-2xl dark:text-white mb-0"></i>
                                    </div>

                                    <div className="flex-1 ms-6">
                                        <h5 className="text-lg dark:text-white mb-2 font-medium">{t("Email")}</h5>
                                        <a href="mailto:alatab.engineering@gmail.com" className="text-slate-400 block">alatab.engineering@gmail.com</a>
                                        <a href="mailto:alatab.engineering@gmail.com" className="text-slate-400">whitehand.uae@gmail.com</a>
                                    </div>
                                </div>

                                <div className="flex mt-4">
                                    <div className="icons text-center mx-auto">
                                        <i className="uil uil-map-marker block rounded text-2xl dark:text-white mb-0"></i>
                                    </div>

                                    <div className="flex-1 ms-6">
                                        <h5 className="text-lg dark:text-white mb-2 font-medium">{t("Location")}</h5>
                                        <p className="text-slate-400 mb-4">{t("locAtabaUAE")}</p>
                                        {/* <p className="text-slate-400 mb-2">{t("locAtabaEgypt")}</p> */}
                                        <p className="text-slate-400 mb-4">{t("locWhiteUAE")}</p>
                                        <p className="text-slate-400">{t("locWhiteEgypt")}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );

}
