import React from "react";

import AboutImage from "../assets/images/ataba-about.jpg";
import "../../node_modules/react-modal-video/scss/modal-video.scss";
import { useTranslation } from "react-i18next";

export default function About() {
  const {t} = useTranslation()

  return (
    <>
      <section className="relative md:py-24 py-16 bg-gray-50 dark:bg-slate-800" id="about">
        <div className="container mx-auto">
          <div className="grid grid-cols-1 lg:grid-cols-12 md:grid-cols-2 gap-10 items-center">
            <div className="lg:col-span-5">
              <div className="relative">
                <img src={AboutImage} className="rounded-lg shadow-lg relative" alt="" />
                {/* <div className="absolute bottom-2/4 translate-y-2/4 start-0 end-0 text-center">
                  <Link2 to="#" onClick={() => setOpen(true)} className="lightbox h-20 w-20 rounded-full shadow-lg shadow-slate-100 dark:shadow-slate-800 inline-flex items-center justify-center bg-white dark:bg-slate-900 text-orange-600">
                    <i className="mdi mdi-play inline-flex items-center justify-center text-2xl"></i>
                  </Link2>
                </div> */}
              </div>
            </div>
            {/* end col */}

            <div className="lg:col-span-7">
              <div className="lg:ms-7">
                <h6 className="text-orange-600 text-base font-medium uppercase mb-2">{t("Who We Are")} ?</h6>
                <h3 className="mb-4 md:text-2xl text-xl font-medium dark:text-white">{t("Our Company Story")}</h3>

                <p className="text-slate-400 dark:text-slate-300 max-w-2xl mx-auto">{t("Al ATABA Company was established In the Emirate of Abu Dhabi, the focus was on development activities and the civilization boom that established the rules of security and stability under the guidance of the rational leadership, starting with the late Sheikh / Zayed bin Sultan Al Nahyan, may God have mercy on him and his blessings, and carry the banner of action to His Highness Sheikh /Khalifa bin Sultan Al Nahyan of Abu Dhabi Crown Prince Sheikh / Mohammed bin Zayed Al Nahyan - to support, establish and encourage small institutions in the country, general contracting work started in the fields of construction, general maintenance, and agricultural work of all kinds of agricultural development works, operation and maintenance")}</p>

                <div className="relative mt-10">
                  <a href="#portfolio" className="btn bg-orange-600 hover:bg-orange-700 border-orange-600 hover:border-orange-700 text-white rounded-md">{t("View Portfolio")}</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End --> */}
    </>
  );

}

